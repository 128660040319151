import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import {routingMixin as RouterMixin} from '@jack-henry/web-component-router';

const MESSAGES = {
  RETRIEVING_EULA: 'Retrieving terms of use...',
  ERROR_RETRIEVING_EULA: 'Terms of use could not be retrieved. Try again later.',
  STANDARD_EULA: 'Standard terms of use',
};

const PAGE_TITLE = 'Terms of use :: Banno';

/**
 * @constructor
 * @polymer
 * @extends {LitElement}
 */
const EulaContainerBase = RouterMixin(LitElement);

/** @customElement */
class EulaContainerElement extends EulaContainerBase {
  static get is() {
    return 'eula-container';
  }

  static get properties() {
    return {
      content: {
        type: String,
      },
      header: {
        type: String,
      },
      institutionId: {
        type: String,
        attribute: 'institution-id',
      },
      mode: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    /** @type {?string} */
    this.content = null;

    /** @type {string} */
    this.header = MESSAGES.STANDARD_EULA;

    /** @type {?string} */
    this.institutionId = null;

    /** @type {?string} */
    this.mode = null;
  }

  async maybeGetEula() {
    if (!this.institutionId) {
      this.content = MESSAGES.ERROR_RETRIEVING_EULA;
      return;
    }

    this.content = MESSAGES.RETRIEVING_EULA;

    try {
      if (this.mode === 'retail' || this.mode === 'business') {
        // /eula/retail/{institutionId}
        this.header = MESSAGES.STANDARD_EULA;

        const res = await fetch(`/a/mobile/api/institutions/${this.institutionId}/eula`);
        const eula = await res.json();
        const eulaKey = this.mode === 'retail' ? 'retailEula' : 'businessEula';
        if (!eula || !eula[eulaKey]) {
          throw new Error('Unexpected response');
        }
        this.content = eula[eulaKey];
      } else if (this.mode === 'default') {
        // /eula/default/{institutionId}
        this.header = MESSAGES.STANDARD_EULA;

        const res = await fetch(`/a/mobile/api/institutions/${this.institutionId}/eula`);
        const eula = await res.json();
        if (!eula || !eula.default) {
          throw new Error('Unexpected response');
        }
        this.content = eula.default;
      } else {
        // /eula/{institutionId}
        const res = await fetch(`/a/consumer/api/institutions/${this.institutionId}`);
        const fiConfig = await res.json();
        if (!fiConfig || !fiConfig.eula) {
          throw new Error('Unexpected response');
        }
        this.content = fiConfig.eula.text;
        this.header = `${fiConfig.name} user agreement`;
      }
    } catch (e) {
      this.content = MESSAGES.ERROR_RETRIEVING_EULA;
    }
  }

  async routeEnter(currentNode, nextNodeIfExists, routeId, context, next) {
    await super.routeEnter(currentNode, nextNodeIfExists, routeId, context, next);
    document.title = PAGE_TITLE;
    this.maybeGetEula();
  }

  static get styles() {
    return [jhaStyles, css`
      :host {
        display: block;
        width: 90%;
        min-height: 0;
        max-height: none;
        max-width: 650px;
        border-radius: 2px;
        box-shadow: 0 3px 12px 0 rgba(37,49,62,.24),0 3px 24px rgba(37,49,62,.12);
        border: none;
        margin: auto;
        padding: 0;
        background: white;
        margin-top: 126px;
      }
      @media (max-width: 480px) {
        :host {
          border-radius: 0;
          width: 100%;
          height: 100%;
          margin-top: 50px;
        }
      }
      header {
        padding: 0px 24px 0px 0px;
        margin-left: 24px;
      }
      article {
        padding: 0px 24px 16px 0px;
        margin-left: 24px;
      }
      article p {
        white-space: pre-line;
      }
    `];
  }

  render() {
    return html`
      <header>
        <h1>${this.header}</h1>
      </header>
      <article>
        <p>${this.content}</p>
      </article>
    `;
  }
}
customElements.define(EulaContainerElement.is, EulaContainerElement);
export default EulaContainerElement;
